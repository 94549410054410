<template>
  <div v-if="model">
    <video
      preload="auto"
      crossorigin=""
      id="myVideo"
      poster="/images/bg/transparent.png"
      @ended="finished"
      @error="error"
      @play="started"
    >
      <source />
      Your browser does not support HTML5 video.
    </video>
    <canvas id="canvas" hidden></canvas>
    <div class="content"></div>
  </div>
</template>

<script>
import store from "../store/index";

import advertisementManager from "../manager/modules/advertisement-manager";
export default {
  name: "advertisement",
  props: ["data"],
  mounted() {
    this.playVideo();
  },
  data() {
    return {
      firstFired: false,
      secondFired: false,
      thirdFired: false,
      t: null,
    };
  },
  methods: {
    startTimer(vid) {
      this.t = setInterval(() => {
        var percent = parseInt((vid.currentTime / vid.duration) * 100);
        if (!this.firstFired && percent === 25) {
          this.fireEvents(1);
          this.firstFired = true;
        }
        if (!this.secondFired && percent === 50) {
          this.fireEvents(2);
          this.secondFired = true;
        }
        if (!this.thirdFired && percent === 75) {
          this.fireEvents(3);
          this.thirdFired = true;
        }
      }, 1);
    },
    fireEvents(quarter) {
      var url = null;
      switch (quarter) {
        case 0: // start
          {
            url = this.model.startLink;
          }
          break;
        case 1: // 25%
          {
            url = this.model.firstQuartileLink;
          }
          break;
        case 2: // 50 %
          {
            url = this.model.midpointLink;
          }
          break;
        case 3: // 75 %
          {
            url = this.model.thirdQuartileLink;
          }
          break;
        case 4: // completed
          {
            url = this.model.completeLink;
          }
          break;
        default:
          break;
      }
      if (url) {
        advertisementManager.callEvent(url);
      }
    },
    started() {
      this.fireEvents(0);
      advertisementManager.downloadNextAdvertisement();
    },
    async finished() {
      this.fireEvents(4);
      clearInterval(this.t);
      await advertisementManager.deleteAdvertisement(this.model.mediaFile);
      store.dispatch("setNextPlaylist");
    },
    error() {
      store.dispatch("setNextPlaylist");
    },
    playVideo() {
      var video = document.getElementById("myVideo");
      video.src = this.model.mediaFile;
      video.type = "video/mp4";
      video.muted = true;

      var playPromise = video.play();
      if (playPromise === undefined) {
        window.console.log("playPromise undefined.");
        store.dispatch("setNextPlaylist");
        return;
      }
      this.startTimer(video);

      playPromise
        .then(() => {})
        .catch((error) => {
          window.console.log("Video Error: " + error);
          store.dispatch("setNextPlaylist");
        });
    },
  },
  computed: {
    model() {
      return this.$store.state.advertisement;
    },
  },
};
</script>

<style lang="scss" scoped>
#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
  top: 0px;
  color: #f1f1f1;
}
</style>
